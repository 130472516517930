<template>
  <el-dialog class="el-dialog-cus" v-bind="attributes" :visible="visible" :before-close="beClose" :append-to-body="append" :close-on-click-modal="false" v-on="on" :show-close="showClose"  :width="width">
  <!-- <el-dialog v-el-drag-dialog class="el-dialog-cus" v-bind="attributes" :visible="visible" :before-close="beClose" :append-to-body="append" :close-on-click-modal="false" v-on="on" :show-close="showClose"  :width="width"> -->
    <slot v-if="visible"></slot>
    <div slot="footer" v-if='btnTxt.length' class="footer">
      <el-button @click="confirm" type="primary" :loading="isdeling">{{btnTxt[0]}}</el-button>
      <el-button @click="cancel" type="primary" plain v-if="btnTxt[1]" :loading="isdeling">{{btnTxt[1]}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      config: Object,
      autoClose: {
        type: Boolean,
        default: true,
      },
      append:{
        type: Boolean,
        default: false,
      },
      visible: {
        type: Boolean,
      },
      beforeClose: {
        type: Function,
        default: () => { },
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
      isdeling: {
        type: Boolean,
        default: false
      },
      showClose: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: "26%"
      }
    },
    data() {
      const { top = '20vh', width = '420px', title = '提示', center = false, btnTxt = [] } = this.config || {};
      return {
        attributes: {
          top,
          width,
          title,
          center,
        },
        btnTxt,
        on: this.getDialogEvents(),
      };
    },
    methods: {
      cancel() {
        this.cancelDialog();
      },
      confirm() {
        this.confirmDialog();
      },
      beClose(done) {
        done();
        this.beforeClose();
        this.cancel();
      },
      getDialogEvents() {
        // closed: () => this.visibleSlot = false是为了防止弹窗中的内容先于弹窗消失而造成弹窗在关闭时有一个突然向上缩小的情况
        let { close } = this.config || {}, events = { closed: () => this.visible = false };
        if (close && typeof close == 'function') {
          Object.assign(events, {
            close,
          })
        }
        return events
      },
    },
    watch:{
      'config.title'(val){
        if(val){
          this.attributes.title = val
          // this.demo();
        }
      }
    },
  };
</script>

<style lang="less">
  .el-dialog-cus {
    .el-dialog {
      padding: 8px;
    }
    .el-dialog__title {
      font-weight: normal;
    }
    .el-dialog__header {
      padding: 20px 0 12px;
    }
    .el-dialog__headerbtn {
      top: 8px;
      right: 8px;
    }
    .el-dialog__body {
      padding: 0 24px;
    }
    .el-dialog__footer {
      text-align: center;
      padding: 20px;
      .el-button {
        padding: 8px 20px;
        width: 80px;
        & + .el-button {
          margin-left: 40px;
        }
      }
    }
  }
</style>
