<!--
 * @Author: fangting fangting@whty.com.cn
 * @Date: 2022-12-13 10:05:59
 * @LastEditors: fangting fangting@whty.com.cn
 * @LastEditTime: 2022-12-26 14:00:03
 * @FilePath: \web\src\views\enterprise\modules\dialog\checkKey.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <Dialogs ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='submitGroupForm' :isdeling='isdeling' append>
        <div>
          <el-form :model="ruleForm"  ref="ruleForm" label-width="70px" class="demo-ruleForm">
           <el-form-item label="APPID" prop="appId" >
              <el-input type="textarea" v-model="ruleForm.appId" disabled></el-input>
           </el-form-item>
           <el-form-item label="签名私钥" prop="privateKey">
              <el-input type="textarea" v-model="ruleForm.privateKey" maxlength="2048" disabled></el-input>
           </el-form-item>
           <el-form-item type="textarea" label="通知公钥" prop="publicKey">
              <el-input type="textarea" v-model="ruleForm.publicKey"  maxlength="2048" disabled></el-input>
           </el-form-item>
          </el-form>
          <el-button @click="cancelDialog" type="primary" class="dialogClass">关闭</el-button>
        </div>
       
  </Dialogs>
</template>

<script>
import Dialogs from "@/components/Dialogs/index";
import { regionData ,CodeToText } from 'element-china-area-data'
export default {
  components: {
    Dialogs,
  },
  props: {
    type:{
      type: String,
    },
    rowData :{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  
  data() {
   return {
      config: {
        top: '14vh',
        width: '22rem',
        title: '查看密钥',
        center: true,
        // btnTxt: [''],
      },
      ruleForm: {
          appId:'',
          privateKey: '',
          publicKey:'',
        },
      // rules: {
      //     year: [
      //       { required: true, message: '请输入续费年限', trigger: 'blur' },
      //     ],
      //   }
    };
  },
  methods: {
      submitGroupForm(){
        console.log(this.$refs,'this.$refs')
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
           this.confirmDialog(this.ruleForm) 
          }
        })
      },
  },
  watch:{
    rowData: function(newValue,oldValue){
      this.ruleForm.appId = newValue.id
      this.ruleForm.privateKey = newValue.platformPrivateKey
      this.ruleForm.publicKey = newValue.platformPublicKey
    },
  }
};
</script>

<style lang="less" scoped>
  .groupForm{
    width: 100%;
    margin: 0 auto;
    .submitGroupBtn{
      margin: 20px auto 0;
      display: block;
    }
  }
  /deep/ .el-form--label-top .el-form-item__label{
    padding: 0;
  }
  /deep/ .el-form-item {
    margin-bottom: 0.7rem;
  }
  .dialogClass{
    margin-left: 200px;
    margin-bottom: 20px;
  }
</style>
