<template>
    <div class="app-container">
        <nav>
            <div class="tips">
                <!-- <div class="text">通讯码</div> -->
                <el-input v-model="queryValue" placeholder="请输入企业名称"></el-input>
                <el-button @click="initTable" type="primary" class="btn">查询</el-button>
            </div>
            <div class="operation">
                <el-button @click="add" type="primary" class="btn">+ 新增</el-button>
            </div>
        </nav>

        <div class="insurance_table" v-loading='isLoading'>
            <comps-table :tableData="tableData" :tableHeader="tableHeader"  :total='total'  @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length" >
            </comps-table>
            <nodata v-else message="暂无数据" class="no_data" />
        </div>
        <key-dialog :visible='checkKeyVis' :cancelDialog='cancelDialog' :rowData='rowData'/>
        <forbidden-dialog :visible='forbiddenVis' :cancelDialog='forbiddenCancel' :rowData='rowData' :confirmDialog='confirmDialog'/>

    </div>
    
</template>

<script>
   import compsTable from "@/components/table/table";
   import { insurancesList, deleteInsurances } from '@/api/insurances'
   import keyDialog from './dialog/checkKey'
   import forbiddenDialog from './dialog/forbidden'
    export default {
    components: { compsTable, keyDialog, forbiddenDialog},
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [
          //企业名称 纳税人识别号 申请日期 状态 备注      
          {prop: "companyName", label: "企业名称", width:'240'},
          {prop:'taxId',label:'纳税人识别号',},
          {prop:'createTime',label:'申请日期',},
          {prop:'status',label:'状态',
          render: (h, data) => {
              return (
                <span>
                  {data.row.status=='00'?'禁用':'启用'}
                </span>
              );
            },
          },
          // {prop:'companyRatio',label:'备注',},
          {
            label: "操作",
            center: 'center',
              // <el-button {...{attrs: {disabled: data.row.status=='01'?'true':'false'}}} style='margin-right: 10px;' type="text" onClick={() => { this.forbidden(data.row);}}>
              //   禁用
              // </el-button>
            width:'200',
            render: (h, data) => {
              if(data.row.status == '01'){
              return (
                  <span>
                    <el-button style='margin-right: 10px;' type="text" onClick={() => { this.edit(data.row);}}>
                      编辑 
                    </el-button>
                    <el-button style='margin-right: 10px;' type="text" onClick={() => { this.checkKey(data.row);}}>
                      查看密钥 
                    </el-button>
                    <el-button style='margin-right: 10px;' type="text" onClick={() => { this.forbidden(data.row);}}>
                      禁用
                    </el-button>
                  </span>
                );
              }else{
                return (
                  <span>
                      <el-button style='margin-right: 10px;' type="text" onClick={() => { this.edit(data.row);}}>
                        编辑 
                      </el-button>
                      <el-button style='margin-right: 10px;' type="text" onClick={() => { this.checkKey(data.row);}}>
                        查看密钥 
                      </el-button>
                    </span>
                 )
              }
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 20,
        isdeling: false,
        multipleSelection:[],
        

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },

        rowData:{},
        checkKeyVis:false,//查看密钥弹窗
        forbiddenVis:false,//禁用弹窗
        forbiddenId:'',
        

      }
    },
    methods: {
      add() {
        // 方法
            // this.$emit('change', false)  
            this.$emit('change', '2')  
      },
      //编辑
      edit(row) {
        this.$emit('change', '3', row.id)  
        // this.$router.push({
        //   path: 'customerInfo/edit',
        //   query: {
        //     name: row.name,
        //     companyRatio: row.companyRatio,
        //     personalRatio: row.personalRatio,
        //     isDefault: row.isDefault,
        //     id: row.id
        //   }
        // })
      },
    //查看密钥
    checkKey(row) {
       this.rowData = row
       this.checkKeyVis = true;
    },
    cancelDialog(){
        this.checkKeyVis = false;
    },
    
    //禁用
    forbidden(row) {
       this.forbiddenVis = true;
       this.forbiddenId = row.id
    },
    forbiddenCancel(){
        this.forbiddenVis = false;
    },
    confirmDialog() {
        this.$store.dispatch("forbidJoinCompany", { id:this.forbiddenId,
          }).then(res => {
            this.isLoading = true;
            if (res.success) {
               this.isLoading = false;
               einvAlert.success('提示',res.msg,'我知道了')
               this.initTable()
            } else {
              einvAlert.warning('提示',res.msg)
              this.isLoading = false
            }
          }).catch(err => {
            einvAlert.error("提示",err)
            this.isLoading = false
          })
        this.forbiddenVis = false;
        // einvAlert.success("提交成功");
      },
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      // 查询列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("findjoinCompany", { 
          companyName: this.queryValue,
          userId: this.getToken('userId'),
          page: this.page,
          rows: this.pageSize,
          }).then(res => {
            this.isLoading = true;
            if (res.success) {
              this.isLoading = false;
              this.tableData = res.data;
              this.total = res.totalCount;
            } else {
              einvAlert.warning('提示',res.msg)
              this.isLoading = false
            }
          }).catch(err => {
            einvAlert.error("提示",err)
            this.isLoading = false
          })
      },

      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
     handleExport(){
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
     
      tipsCancel(){
        this.promptVisible = false;
      }
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less" scoped>
nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }

  .btn1{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
      width: 100%;
    }
  }
</style>